<template>
  <button class="arrow-button">
    {{ text }}

    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.5L9 0.5V8.5" stroke="#2F57E9"/>
      <path d="M1 8.5L9 0.5" stroke="#2F57E9"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-button {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  line-height: 100%;
  text-transform: uppercase;
  color: #2F57E9;
  font-family: SemiBold;

  &.white {
    color: white;

    svg path {
      stroke: #fff;
    }
  }

  &.fs14 {
    font-size: 14px;
  }
}
</style>
