<template>
  <button
    :class="{
      activate: active == value
    }"
    @click="$emit('onclick', value)"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    active: {
      type: [Boolean, String, Number],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  height: 36px;
  margin-right: 5px;
  padding: 0 20px;
  border-radius: 30px;
  border: 1px solid #9CA3B8;
  color: rgba(var(--text-color-light-gray));
  font-family: SemiBold;
  transition: .2s;

  &:hover {
    background: white;
    border: 1px solid transparent;
    color: rgb(var(--color-dark));
  }

  &.activate {
    background: rgba(var(--primary-color), .2);
    border: 1px solid transparent;
    color: rgba(var(--primary-color), 1);
  }

  &:disabled {
    opacity: .7;
    cursor: default;

    &:hover {
      background: transparent;
      border: 1px solid #9CA3B8;
      color: rgba(var(--text-color-light-gray));
    }
  }
}
</style>
