<template>
  <Container
    class="chart"
    :preloader="preloader"
  >
    <template #content>
      <div class="chart__item">
        <div class="chart__item__start">
          {{ $t('components.ui.pages.charts.balance-yield.all') }}

          <ArrowButton
            :text="$t('components.ui.pages.charts.balance-yield.buy-container')"
            @click="$router.push({ name: 'Store' })"
          />
        </div>

        <div class="chart__item__center">
          ${{ $format.wallet(value.purchases_income) }}
        </div>

        <div class="chart__item__end">
          {{ $t('components.ui.pages.charts.balance-yield.personal-purchase') }}

          <span>
            ${{ $format.wallet(value.purchases) }}
          </span>
        </div>
      </div>

      <div class="chart__item">
        <div class="chart__item__start">
          {{ $t('components.ui.pages.charts.balance-yield.referral-income') }}

          <ArrowButton
            :text="$t('components.ui.pages.charts.balance-yield.invite-partner')"
            @click="$pop.up('invite')"
          />
        </div>

        <div class="chart__item__center">
          ${{ $format.wallet(value.referral_total_income) }}
        </div>

        <div class="chart__item__end">
          <div class="chart__item__end__info">
            <div class="chart__item__end__info__item to-left">
              <div class="chart__item__end__info__item-name">
                {{ $t('components.ui.pages.charts.balance-yield.for-container-purchases') }}
              </div>

              <div class="chart__item__end__info__item-value">
                ${{ $format.wallet(value.referral_income) }}
              </div>
            </div>

            <div class="chart__item__end__info__item to-right">
              <div class="chart__item__end__info__item-name">
                {{ $t('components.ui.pages.charts.balance-yield.for-purchases-goods') }}
              </div>

              <div class="chart__item__end__info__item-value">
                ${{ $format.wallet(value.referral_products_income) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

export default {
  components: {
    Container,
    ArrowButton
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      value: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get(
          '/private/widgets/income')
        if (status === 200) {
          this.value = data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  padding: 10px 20px;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__item {
    height: 100%;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 35px;

    &:first-child {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid rgba(156, 163, 184, .6);

      @media screen and (max-width: 768px) {
        padding-right: 0;
        padding-bottom: 20px;
        margin-right: 0;
        margin-bottom: 10px;
        border-right: none;
        border-bottom: 1px solid rgba(156, 163, 184, .6);
      }
    }

    &__start {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    &__center {
      font-size: 30px;
      line-height: 100%;
      text-align: center;
      color: #202539;
      font-family: SemiBold;
    }

    &__end {
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      color: #787C8D;
      font-family: Medium;

      span {
        font-size: 12px;
        line-height: 100%;
        color: #00B307;
        font-family: SemiBold;
      }

      &__info {
        display: flex;
        justify-content: space-between;

        &__item {
          &.to-left {
            text-align: left;
          }

          &.to-right {
            text-align: right;
          }

          &-name {
            font-size: 12px;
            line-height: 100%;
            color: #787C8D;
            font-family: Medium;
          }

          &-value {
            margin-top: 5px;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            font-family: SemiBold;
          }
        }
      }
    }
  }
}
</style>
