<template>
  <Container
    class="chart"
    :preloader="preloader"
    style="overflow: hidden !important;"
  >
    <template #content>
      <div class="chart__start">
        {{ $t('components.ui.pages.charts.balance-line.balance') }}

        <ArrowButton
          :text="$t('components.ui.pages.charts.balance-line.withdrawal')"
          @click="$pop.up('withdrawal')"
        />
      </div>

      <h2>
        ${{ $format.wallet(data.balance, ',') }}
      </h2>

      <LineChart
        v-if="!preloader"
        class="chart__graph"
        :chart-data="datacollection"
        :options="options"
        :plugins="plugins"
      />

      <div class="chart__end">
        {{ $t('components.ui.pages.charts.balance-line.min') }}

        <span>
          ${{ $format.wallet(data.min_withdraw) }}
        </span>
      </div>
    </template>
  </Container>
</template>

<script>
import { Chart, registerables } from 'chart.js'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'
import { LineChart } from 'vue-chart-3'

Chart.register(...registerables)

export default {
  components: {
    Container,
    ArrowButton,
    LineChart
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      data: {},
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'rgba(15, 191, 230, 0.5)',
            borderWidth: 0.5,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 75)

              gradient.addColorStop(0, 'rgba(15, 191, 230, 0.3)')
              gradient.addColorStop(1, 'rgba(15, 191, 230, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          }
        ]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        plugins: {
          responsive: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        elements: {
          point: {
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent'
          }
        },
        layout: {
          padding: 0
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true
          },
          x: {
            display: false,
            afterFit: scale => {
              scale.paddingLeft -= 10
              scale.paddingRight -= 10
            }
          }
        }
      }
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/balance-with-graph')
        if (resp.status === 200) {
          this.data = resp.data.data
          this.parsing()
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.preloader = false
        }, 200)
        this.mountFetch = true
      }
    },
    parsing () {
      const val = this.data.graph
      var data = []

      for (const i in val) {
        data.push(val[i])
      }

      data.push(data[data.length - 1])

      this.datacollection.labels = data
      this.datacollection.datasets[0].data = data
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  padding: 20px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;

  &__start {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  h2 {
    position: relative;
    font-size: 36px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
    text-align: center;
    z-index: 2;
  }

  &__graph {
    position: absolute !important;
    left: -20px;
    bottom: 35px;
    height: 100%;
    max-height: 80px;
    width: calc(100% + 40px) !important;
    max-width: none !important;
  }

  &__end {
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #787C8D;
    font-family: Medium;

    span {
      color: #00B307;
      font-family: SemiBold;
    }
  }
}
</style>
