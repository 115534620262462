<template>
  <button
    class="settings"
    @click.stop="$emit('onclick')"
  >
    <svg
      v-if="settings"
      width="20"
      height="20"
      style="min-width: 20px; min-height: 20px;"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 3H4M4 3H19M4 3V5M4 3V1M16 12V10M16 10V8M16 10H1M16 10H19M10 19V17M10 17V15M10 17H1M10 17H19" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </svg>

    <svg
      v-else-if="invite"
      width="20"
      height="20"
      style="min-width: 20px; min-height: 20px;"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.89844 18.1004C1.89844 14.1239 5.12199 10.9004 9.09844 10.9004C10.0532 10.9004 10.9646 11.0862 11.7984 11.4238M14.4984 10.9004V14.5004M14.4984 14.5004V18.1004M14.4984 14.5004H10.8984M14.4984 14.5004H18.0984M12.6984 5.05039C12.6984 6.79009 11.2881 8.20039 9.54844 8.20039C7.80874 8.20039 6.39844 6.79009 6.39844 5.05039C6.39844 3.31069 7.80874 1.90039 9.54844 1.90039C11.2881 1.90039 12.6984 3.31069 12.6984 5.05039Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg
      v-else-if="plus"
      width="20"
      height="20"
      style="min-width: 20px; min-height: 20px;"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 2V18M2 10H18" stroke="white" stroke-width="3" stroke-linecap="round"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    settings: {
      type: Boolean,
      default: false
    },
    invite: {
      type: Boolean,
      default: false
    },
    plus: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  height: 36px;
  width: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgb(var(--primary-color));
  border-radius: 50%;
  transition: .2s;

  &:hover {
    background: rgba(var(--color-light-blue));
  }

  &:active {
    background: rgba(var(--color-dark));
  }

  &.active {
    background: rgba(var(--color-dark));
  }

  svg {
    min-height: 20px;
    min-width: 20px;
  }
}
</style>
