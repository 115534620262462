<template>
  <Container
    v-if="(!preloader && _value.length > 0) || preloader"
    class="payment"
    :preloader="preloader"
  >
    <template #content>
      <div class="payment__top">
        <div class="payment__top__flex">
          {{ $t('components.ui.pages.charts.balance-payment.next-payment') }}

          <div class="payment__top__flex__label">
            {{ $t('components.ui.pages.charts.balance-payment.left-days', { n: data.days }) }}
          </div>
        </div>

        <h3>
          $ {{ $format.wallet(data.next?.amount ? data.next.amount : 0, ',') }}
        </h3>
      </div>

      <PointProgressBar
        auto
        :collected="collected"
        :value="_value"
      />
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import PointProgressBar from '@/modules/charts/PointProgressBar.vue'

export default {
  components: {
    Container,
    PointProgressBar
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      collected: 0,
      data: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    _value () {
      const data = []
      const value = this.data

      if (value?.prev) {
        data.push({
          title: value.prev.date,
          sum: value.prev.amount
        })
      }

      if (value?.next) {
        data.push({
          title: value.next.date,
          sum: value.next.amount
        })
      }

      if (value?.future) {
        data.push({
          title: value.future.date,
          sum: value.future.amount
        })
      }

      return data
    }
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/next-payment')
        if (resp.status === 200) {
          this.data = resp.data.data
          // this.data = { days: 1, next: { date: '06.01.2023', amount: '3414.15' }, future: { date: '9.01.2023', amount: '3414.15' }, prev: { date: '21.12.2022', amount: '3442.15' } }
          this._collected(this.data)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    // It's converting the date string to a date object.
    _parseDate (date) {
      const day = date.slice(0, 2)
      const month = date.slice(3, 5)
      const year = date.slice(6, 10)

      return `${month}/${day}/${year}`
    },
    // It's calculating the percentage of the collected amount.
    _collected (data) {
      const start = data.prev?.date
      const end = data.next?.date || data.future?.date

      if (start && end) {
        const dateStart = new Date(this._parseDate(start))
        const dateEnd = new Date(this._parseDate(end))

        // It's calculating the number of days between two dates.
        const daysLag = Math.abs((dateEnd.getTime() - dateStart.getTime()) / (1000 * 3600 * 24))
        const dateNow = new Date()
        const daysLagPrev = Math.abs((dateNow.getTime() - dateStart.getTime()) / (1000 * 3600 * 24))

        const percent = data.future?.date ? Math.abs(((100 / daysLag) * daysLagPrev) / 2)
          : Math.abs((100 / daysLag) * daysLagPrev)
        this.collected = percent
      } else {
        this.collected = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__flex {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;

      &__label {
        height: 22px;
        margin-left: 10px;
        padding: 0 17px;
        display: flex;
        align-items: center;
        background: rgba(var(--primary-color), .2);
        border-radius: 20px;
        color: rgba(var(--primary-color), 1);
        font-size: 12px;
        font-family: Medium;
        white-space: nowrap;
      }
    }

    h3 {
      color: black;
      font-size: 30px;
      font-family: SemiBold;
      line-height: 100%;
      text-align: center;
    }
  }
}
</style>
