<template>
  <UiPage
    :title="$t('app.page.balance')"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="chart-grid">
        <Balance
          class="balance-chart"
        />

        <Yield />

        <Payment />
      </div>

      <div class="balance-history">
        <h3>
          {{ $t('views.default.balance.balance-story') }}
        </h3>

        <Container
          class="balance-history__container"
          :preloader="preloader"
        >
          <template #content>
            <div class="balance-history__container__top">
              <div class="balance-history__container__top__tabs">
                <TabButton
                  :text="$t('views.default.balance.all-transaction')"
                  :value="'all'"
                  :active="activeTab"
                  @click="activeTab = $event"
                />

                <TabButton
                  :text="$t('views.default.balance.income')"
                  :value="'income'"
                  :active="activeTab"
                  @click="activeTab = $event"
                />

                <TabButton
                  :text="$t('views.default.balance.withdrawal')"
                  :value="'withdraw'"
                  :active="activeTab"
                  @click="activeTab = $event"
                />
              </div>

              <FilterRangeDate
                class="filter-list"
                :value="date"
                @updateDate="date = $event"
              />
            </div>

            <div
              v-if="$store.state.window.width > 1024"
              class="balance-history__container__tables"
            >
              <table>
                <thead>
                  <tr>
                    <th>
                      {{ $t('views.default.balance.date') }}
                    </th>

                    <th>
                      ID
                    </th>

                    <th>
                      {{ $t('views.default.balance.transaction') }}
                    </th>

                    <th>
                      {{ $t('views.default.balance.amount') }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template
                    v-if="data.length > 0"
                  >
                    <template
                      v-for="item in data"
                      :key="item.id"
                    >
                      <tr>
                        <td>
                          {{ $format.dateTime(item.updated_at ? item.updated_at : item.created_at) }}
                        </td>

                        <td>
                          ID:{{ item.id }}
                        </td>

                        <td class="description">
                          {{ $t(`views.default.balance.transaction-${item.type}`) }}
                        </td>

                        <td class="val">
                          <div class="val__flex">
                            <div
                              class="val__icon"
                              :class="{
                                minus : item.amount < 0,
                                plus : item.amount > 0
                              }"
                            >
                              <svg
                                v-if="item.amount > 0"
                                width="14" height="14"
                                style="min-width: 14px; min-height: 14px;"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7 1V13M1 7H13" stroke="#00B307" stroke-width="2" stroke-linecap="round"/>
                              </svg>

                              <svg
                                v-else
                                width="14" height="2"
                                style="min-width: 14px; min-height: 2px;"
                                viewBox="0 0 14 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 1H13" stroke="#DA2828" stroke-width="2" stroke-linecap="round"/>
                              </svg>
                            </div>

                            $ {{ $format.wallet(abs(item.amount), ',') }}
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>

                  <tr v-else>
                    <td>
                      —
                    </td>

                    <td>
                      —
                    </td>

                    <td>
                      —
                    </td>

                    <td>
                      —
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-else
              class="balance-history__container__cards"
            >
              <template
                v-if="data.length > 0"
              >
                <template
                  v-for="item in data"
                  :key="item.id"
                >
                  <div class="balance-history__container__cards__item">
                    <div class="balance-history__container__cards__item__start">
                      <p class="small-text">
                        {{ $format.dateTime(item.updated_at ? item.updated_at : item.created_at) }}
                      </p>

                      <p class="small-text">
                        ID:{{ item.id }}
                      </p>
                    </div>

                    <div class="balance-history__container__cards__item__end">
                      <div class="balance-history__container__cards__item__end__info">
                        <!-- <p class="little-text">
                          {{ $t('views.default.balance.debit') }}
                        </p> -->

                        <p class="card-text">
                          {{ $t(`views.default.balance.transaction-${item.type}`) }}
                        </p>
                      </div>

                      <div class="balance-history__container__cards__item__end__bottom">
                        <div class="balance-history__container__cards__item__end__bottom__left">
                          <div
                            class="val__icon"
                            :class="{
                              minus : item.amount < 0,
                              plus : item.amount > 0
                            }"
                          >
                            <svg
                              v-if="item.amount > 0"
                              width="14" height="14"
                              style="min-width: 14px; min-height: 14px;"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 1V13M1 7H13" stroke="#00B307" stroke-width="2" stroke-linecap="round"/>
                            </svg>

                            <svg
                              v-else
                              width="14" height="2"
                              style="min-width: 14px; min-height: 2px;"
                              viewBox="0 0 14 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 1H13" stroke="#DA2828" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                          </div>

                          $ {{ $format.wallet(abs(item.amount), ',') }}
                        </div>

                        <div
                          class="balance-history__container__cards__item__end__bottom__right"
                          :class="{
                            minus : item.amount < 0,
                            plus : item.amount > 0
                          }"
                        >
                          {{ item.amount > 0 ? $t('views.default.balance.paid') : $t('views.default.balance.debit') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <div
                v-else
                class="balance-history__container__cards__item none"
              >
                ———
              </div>
            </div>

            <div
              v-if="count > data.length"
              class="balance-history__container__buttons"
            >
              <LightButton
                style="max-width: 250px;"
                :text="$t('views.default.balance.show-more')"
                to-bottom
                loaded
                :loading="isFetchMore"
                :disabled="isFetchMore"
                @click="fetchMore()"
              />
            </div>
          </template>
        </Container>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BackButton from '@/ui/buttons/BackButton.vue'

import Balance from '@/modules/balance/chart/Balance.vue'
import Yield from '@/modules/balance/chart/BalanceYield.vue'
import Payment from '@/modules/charts/BalancePayment.vue'

import TabButton from '@/ui/buttons/TabButton.vue'
import FilterRangeDate from '@/ui/FilterRangeDate.vue'
import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    UiPage,
    Container,
    BackButton,
    Balance,
    Yield,
    Payment,
    TabButton,
    FilterRangeDate,
    LightButton
  },
  data () {
    return {
      preloader: true,
      mountFetch: false,
      length: 10,
      activeTab: 'all',
      count: 0,
      data: [],
      date: null,
      isFetchMore: false
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch(false)
    }
  },
  deactivated () {
    this.activeTab = 'all'
    this.date = null

    if (this.data.length > this.length) {
      this.data = this.data.slice(0, this.length + 1)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        if (isCacheFetch) {
          this.preloader = true
        }

        const resp = await this.$axios.get(
          `/private/transactions?limit=${this.length}&offset=0${this.activeTab !== 'all' ? `&type=${this.activeTab}` : ''}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`)
        if (resp.status === 200) {
          this.count = resp.data.count
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this.data.length ? this.data.length : 0
      const link = `/private/transactions?offset=${offset}&limit=${this.length}${this.activeTab !== 'all' ? `&type=${this.activeTab}` : ''}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.data.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    },
    abs (value) {
      return Math.abs(value)
    }
  },
  watch: {
    activeTab () {
      this.fetch()
    },
    date () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-grid {
  display: grid;
  grid-template-columns: 370px auto 370px;
  column-gap: 20px;
}

.balance-history {
  width: 100%;
  margin-top: 40px;

  h3 {
    margin-bottom: 20px;
    color: rgb(var(--color-dark));
    font-family: SemiBold;
  }

  .balance-history__container {
    width: 100%;
    padding: 20px;
    border-radius: 15px;

    .balance-history__container__top {
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(var(--border-line-color));

      .balance-history__container__top__tabs {
        display: flex;
        align-items: center;
      }
    }

    .balance-history__container__tables {
      width: 100%;
      margin: 30px 0 0;

      table {
        width: 100%;
        border-spacing: 0;

        thead {
          tr {
            th {
              padding: 0 20px 20px;
              color: rgba(var(--text-color-light-gray));
              text-align: left;
              font-size: 14px;
              font-family: Medium;
              font-weight: normal;
            }
          }
        }

        tbody {
          tr {
            height: 60px;

            &:nth-child(odd) {
              td {
                background: rgba(240, 242, 248, .5);

                &:first-child {
                  border-radius: 10px 0 0 10px;
                }

                &:last-child {
                  border-radius: 0 10px 10px 0;
                }
              }
            }

            td {
              height: 100%;
              padding: 0 20px 0 20px;
              white-space: nowrap;
              color: rgba(var(--text-color-light-gray));
              font-size: 14px;
              font-family: Medium;

              &.description {
                // width: 100%;
                white-space: normal;
                color: rgb(var(--color-dark));
                font-size: 16px;
              }

              &.val {
                .val__flex {
                  display: flex;
                  align-items: center;
                  color: rgb(var(--color-dark));
                  font-size: 18px;
                  font-family: SemiBold;

                  .val__icon {
                    height: 28px;
                    width: 28px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &.plus {
                      background: rgba(0, 178, 7, .1);
                    }

                    &.minus {
                      background: rgba(218, 40, 40, .1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .balance-history__container__buttons {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .balance-history__container__cards {
      .balance-history__container__cards__item {
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;

        &.none {
          font-size: 14px;
          line-height: 100%;
          color: #9CA3B8;
        }

        &:nth-child(odd) {
          background: #F7F8FB;

          .balance-history__container__cards__item__end {
            .balance-history__container__cards__item__end__info {
              p.card-text {
                &::after {
                  background: linear-gradient(90deg, transparent, #F7F8FB 100%);
                }
              }
            }
          }
        }

        .balance-history__container__cards__item__start {
          width: 100%;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;

          p.small-text {
            font-size: 14px;
            line-height: 100%;
            color: rgb(var(--text-color-light-gray));
            font-family: Medium;
          }
        }

        .balance-history__container__cards__item__end {
          .balance-history__container__cards__item__end__info {
            margin-bottom: 15px;
            line-height: 100%;
            overflow: hidden;

            p.little-text {
              margin-bottom: 4px;
              color: #787C8D;
              font-family: Medium;
            }

            p.card-text {
              position: relative;
              color: rgb(var(--color-dark));
              font-family: Medium;
              white-space: nowrap;
              text-overflow: ellipsis;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 30px;
                background: linear-gradient(90deg, transparent, white 100%);
              }
            }
          }

          .balance-history__container__cards__item__end__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .balance-history__container__cards__item__end__bottom__left {
              display: flex;
              align-items: center;
              font-size: 18px;
              line-height: 100%;
              display: flex;
              align-items: center;
              color: rgb(var(--color-dark));
              font-family: SemiBold;

              .val__icon {
                height: 28px;
                width: 28px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &.plus {
                  background: rgba(0, 178, 7, .1);
                }

                &.minus {
                  background: rgba(218, 40, 40, .1);
                }
              }
            }

            .balance-history__container__cards__item__end__bottom__right {
              padding: 3px 8px;
              background: #00B307;
              border-radius: 10px;
              font-size: 12px;
              line-height: 100%;
              color: #FFFFFF;
              font-family: Medium;

              &.plus {
                background: rgba(0, 178, 7, 1);
              }

              &.minus {
                background: #DA2828;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .chart-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    .balance-chart {
      height: 200px;
    }

    .chart-grid__right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .chart-grid {
    .balance-chart {
      height: 200px;
    }

    .chart-grid__right {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .chart-grid {
    padding: 0 17px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;

    .balance-chart {
      height: auto;
    }

    .chart-grid__right {
      display: flex;
      flex-direction: column-reverse;
      gap: 15px;
    }
  }

  .balance-history {
    padding: 0 17px;

    .balance-history__container {
      padding: 15px;

      .balance-history__container__top {
        margin-bottom: 15px;
        padding-bottom: 10px !important;
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between;
        border-bottom: 1px solid rgb(var(--border-line-color));

        .balance-history__container__top__tabs {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          button {
            margin-bottom: 5px;
          }
        }
      }

      .balance-history__container__buttons {
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px solid rgb(var(--border-line-color));

        button {
          max-width: none !important;
        }
      }
    }
  }
}
</style>
