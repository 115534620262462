<template>
  <div class="container-filter">
    <RoundButton
      settings
      :class="{
        active: visible
      }"
      @click="visible ? visible = false : visible = true"
    />

    <DatePicker
      v-if="visible"
      class="absolute"
      is-range
      is-button
      is-graph
      :original="value?.original"
      @setDate="updateDate($event), visible = false"
      v-click-outside="hide"
    />
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

import RoundButton from '@/ui/buttons/RoundButton.vue'
import DatePicker from '@/ui/inputs/Date.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    RoundButton,
    DatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isGraph: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    // Formatting the date to a string in the format of YYYY-MM-DD.
    formatDate (date) {
      var month = '' + (date.getMonth() + 1)
      var day = '' + date.getDate()
      var year = date.getFullYear()

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [year, month, day].join('-')
    },
    // A method that is called when the date is changed. It is used to emit the date to the parent
    // component.
    updateDate (value) {
      if (value) {
        // value = this.handleInput(value)

        const date = {
          start: this.formatDate(value.start),
          end: this.formatDate(value.end)
        }

        this.$emit('updateDate', {
          parse: date,
          original: value
        })
      } else {
        this.$emit('updateDate', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-filter {
  position: relative;

  &.filter-list {
    .absolute {
      top: auto;
      bottom: calc(-100% + 20px);
      right: calc(100% + 5px);
    }
  }

  .absolute {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 15;
  }
}

@media screen and (max-width: 768px) {
  .container-filter {
    &.filter-list {
      .absolute {
        width: calc(100vw - ((17px + 35px) * 2))
      }
    }

    .absolute {
      width: calc(100vw - ((17px + 15px) * 2));
    }
  }
}
</style>
