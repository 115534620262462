<template>
  <div
    class="progress"
    :class="{
      auto
    }"
  >
    <div
      class="progress__bar"
      :style="{
        width: `${collected || _percent}%`
      }"
    />

    <div
      v-for="(item, index) in value"
      :key="index"
      class="progress__point"
      :class="{
        active: item.sum <= collected && max || (!max && (index === 0 || collected >= _percentPointPosistion(value.length, index) || collected >= 100)),
        left: index === 0,
        center: value.length - 1 > index && index > 0,
        right: value.length - 1 === index
      }"
      :style="{
        left: !auto && value.length - 1 > index && index > 0 ?
          `${_pointPosition(item.sum)}%` : auto && value.length - 1 > index && index > 0 ?
            `${_percentPointPosistion(value.length, index)}%` : ''
      }"
    >
      <p class="progress__point__description small-text">
        {{ item.title }}
      </p>

      <div class="progress__point__label">
        $ {{ $format.wallet(item.sum, ',') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    auto: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      required: true
    },
    collected: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: null
    }
  },
  computed: {
    _percent () {
      return this.collected / (this.max / 100)
    }
  },
  methods: {
    _pointPosition (val) {
      return val / (this.max / 100)
    },
    _percentPointPosistion (length, index) {
      return (100 / (length - 1)) * (index)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  height: 4px;
  width: 100%;
  margin: 60px 0 25px;
  display: flex;
  align-items: center;
  background: rgb(202, 210, 235);
  border-radius: 10px;

  &.auto {
    justify-content: space-between;
  }

  .progress__bar {
    height: 100%;
    background: rgb(var(--primary-color));
    border-radius: 10px;
  }

  .progress__point {
    position: absolute;
    height: 10px;
    width: 10px;
    background: rgb(202, 210, 235);
    border: 3px solid rgb(202, 210, 235);
    border-radius: 50%;

    &.left {
      .progress__point__description {
        left: 0;
        text-align: left;
      }

      .progress__point__label {
        left: 0;
        text-align: left;
      }
    }

    &.right {
      right: 0;

      .progress__point__description {
        right: 0;
        text-align: right;
      }

      .progress__point__label {
        right: 0;
        text-align: right;
      }
    }

    &.center {
      .progress__point__description {
        left: -65px;
        text-align: center;
      }

      .progress__point__label {
        left: -65px;
        text-align: center;
      }
    }

    &.active {
      height: 10px;
      width: 10px;
      background: white;
      border: 3px solid rgb(var(--primary-color));

      &.left {
        .progress__point__description {
          left: -5px;
        }

        .progress__point__label {
          left: -5px;
        }
      }

      .progress__point__description {
        bottom: calc(100% + 10px);
      }

      .progress__point__label {
        top: calc(100% + 10px);
        color: rgb(var(--color-dark));
      }
    }

    .progress__point__description {
      position: absolute;
      bottom: calc(100% + 10px);
      width: 140px;
      color: rgb(var(--text-color-light-gray));
      line-height: 100%;
      font-family: Medium;
      font-size: 14px;

      @media screen and (max-width: 470px) {
        font-size: 12px;
      }
    }

    .progress__point__label {
      position: absolute;
      top: calc(100% + 10px);
      width: 150px;
      color: rgb(var(--text-color-light-gray));
      line-height: 100%;
      font-size: 14px;
      font-family: SemiBold;
    }
  }
}
</style>
